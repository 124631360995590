import React from "react";
import styled from "styled-components";
import bgImage from "../assets/images/bg-rewards.jpg"; // Tło
import trophyIcon from "../assets/images/trophy.png"; // Ikona trofeum
import tShirtIcon from "../assets/images/icon-tshirt.png"; // Ikona koszulki
import certificateIcon from "../assets/images/icon-certificate.png"; // Ikona certyfikatu
import hallOfFameIcon from "../assets/images/icon-halloffame.png"; // Ikona tablicy chwały
import goldFinisherLogo from "../assets/images/Logo-GF.png"; // Nowe logo zamiast tekstu

const Section = styled.section`
  background: url(${bgImage}) no-repeat center/cover;
  color: white;
  text-align: center;
  padding: 5rem 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  @media (max-width: 768px) {
    padding: 3rem 1rem;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6); /* Przyciemnione tło dla czytelności */
  }
`;

const TextContainer = styled.div`
  background: rgba(0, 0, 0, 0.7); /* Ciemne przezroczyste tło */
  padding: 3rem 4rem;
  display: inline-block;
  border-radius: 5px;
  position: relative;
  z-index: 1;

  @media (max-width: 768px) {
    padding: 2rem;
  }
`;

const Logo = styled.img`
  width: 550px; 
  height: auto;
  margin-bottom: 2rem;

  @media (max-width: 768px) {
    width: 280px;
  }
`;

const GoldFinisherTitle = styled.h1`
  font-size: 2.5rem;
  color: #f5b700;
  text-transform: uppercase;
  text-shadow: 2px 2px 10px rgba(245, 183, 0, 0.6);
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    font-size: 1.8rem;
  }
`;

const List = styled.ul`
  list-style: none;
  padding: 0;
  max-width: 600px;
  text-align: left;

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const ListItem = styled.li`
  text-transform: uppercase;
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  margin-bottom: 1rem;
  color: white;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.8);
  font-weight: bold;

  &::before {
    font-size: 1.8rem;
    color: #f5b700;
  }

  img {
    width: 40px;
    height: auto;
    margin-right: 1rem;
  }

  @media (max-width: 768px) {
    font-size: 1rem;

    img {
      width: 30px;
    }
  }
`;

const GoldFinisherRewards = () => {
  return (
    <Section>
      <TextContainer>
        <GoldFinisherTitle>
          CO OTRZYMASZ JAKO <br />
          <Logo src={goldFinisherLogo} alt="Gold Finisher Logo" />
        </GoldFinisherTitle>
        <List>
          <ListItem>
            <img src={trophyIcon} alt="Trofeum" />
            Ekskluzywne trofeum Gold Finishera
          </ListItem>
          <ListItem>
            <img src={tShirtIcon} alt="Koszulka" />
            Koszulkę Gold Finishera
          </ListItem>
          <ListItem>
            <img src={certificateIcon} alt="Certyfikat" />
            Certyfikat ukończenia serii
          </ListItem>
          <ListItem>
            <img src={hallOfFameIcon} alt="Tablica chwały" />
            Miejsce na Złotej Liście
          </ListItem>
        </List>
      </TextContainer>
    </Section>
  );
};

export default GoldFinisherRewards;
