import styled from "styled-components";
import heroBackground from "../assets/images/heroGF.jpg"; // Obraz tła

export const HeroSection = styled.section`
  position: relative;
  width: 100%;
  height: 100vh; /* Pełna wysokość ekranu */
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  overflow: hidden;

  /* Tło */
  background: url(${heroBackground}) no-repeat center center/cover;
  
  /* Pseudo-element dla przyciemnienia */
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6); /* Przyciemnienie dla lepszej czytelności */
    z-index: 1;
  }
`;

export const HeroContent = styled.div`
  position: relative;
  z-index: 2;
  max-width: 1000px;
  color: white;
  text-align: center;
  padding: 2rem;

  @media (max-width: 768px) {
    max-width: 90%;
  }
`;

export const GatsbyImage = styled.img`
  width: 280px; /* Dostosuj szerokość */
  height: auto;
  margin-bottom: 1rem;

  @media (min-width: 768px) {
    width: 650px;
  }
`;

export const Title = styled.h1`
  font-size: 3rem;
  text-shadow: 2px 2px 10px rgba(245, 183, 0, 0.4);
  font-weight: bold;
  text-transform: uppercase;
  color: #f5b700;
  text-shadow: 2px 2px 10px rgba(245, 183, 0, 0.4);
  margin-bottom: 1rem;

  @media (max-width: 1024px) {
    font-size: 2.5rem;
  }

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

export const Subtitle = styled.p`
  font-size: 1.5rem;
  color: #ddd;
  margin-bottom: 2rem;
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.8);

  @media (max-width: 1024px) {
    font-size: 1.2rem;
  }

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

export const Button = styled.a`
    margin: 20px 10px;
    color: white;
    font-size: 1rem;
    border: 2px solid gold;
    font-weight: bold;
    padding: 12px 20px;
    text-transform: uppercase;
    text-decoration: none;
    cursor: pointer;
    transition: 0.4s ease-in-out;
    width: 280px;
    text-align: center;
    display: inline-block;
    background-color: transparent;
    color: gold;
    // border-radius: 30px;
    box-shadow: 5px 5px 10px rgba(66, 68, 90, 0.6);

    &:hover {
        background-color: gold;
        color: black;
    }

    @media (max-width: 768px) {
        width: 200px;
        font-size: 0.9rem;
        padding: 10px 15px;
    }
`;

