import React from "react";
import styled from "styled-components";
import Img1 from "../assets/images/GFgallery1.jpg"; // Zmień na właściwe pliki
import Img2 from "../assets/images/GFgallery2.jpg";
import Img3 from "../assets/images/GFgallery3.jpg";
import Img4 from "../assets/images/GFgallery4.jpg";
import Img5 from "../assets/images/GFgallery5.jpg";
import Img6 from "../assets/images/GFgallery6.jpg";

const Section = styled.section`
  background: linear-gradient(135deg, #1a1a1a, #2b2b2b);
  color: white;
  text-align: center;
  padding: 5rem 2rem;

  @media (max-width: 768px) {
    padding: 3rem 1rem;
  }
`;

const Title = styled.h2`
  text-shadow: 2px 2px 10px rgba(245, 183, 0, 0.5);
  font-size: 2.8rem;
  font-weight: bold;
  color: #f5b700;
  text-transform: uppercase;
  margin-bottom: 2rem;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const Subtitle = styled.p`
  font-size: 1.5rem;
  color: #ddd;
  margin-bottom: 2.5rem;

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

const GalleryGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  max-width: 1000px;
  margin: 0 auto;

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  border-radius: 8px;
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }
`;

const GallerySection = () => {
  return (
    <Section>
      <Title>Gala Gold Finisher 2024</Title>
      <Subtitle>Tak wyglądał nasz wielki finał! Dołącz do grona legend w 2025 roku.</Subtitle>

      <GalleryGrid>
        <Image src={Img1} alt="Gala Ultra Race 2024" />
        <Image src={Img2} alt="Gala Ultra Race 2024" />
        <Image src={Img3} alt="Gala Ultra Race 2024" />
        <Image src={Img4} alt="Gala Ultra Race 2024" />
        <Image src={Img5} alt="Gala Ultra Race 2024" />
        <Image src={Img6} alt="Gala Ultra Race 2024" />
      </GalleryGrid>
    </Section>
  );
};

export default GallerySection;
