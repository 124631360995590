import React from "react";
import styled from "styled-components";
import ultraRaceSeriesImage from "../assets/images/gold-finisher2.png"; // Zmień na właściwą nazwę pliku

const Section = styled.section`
  background: linear-gradient(135deg, #1a1a1a, #2b2b2b);
  color: white;
  padding: 5rem 2rem;

  @media (max-width: 768px) {
    padding: 3rem 1rem;
  }
`;

const Content = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 3rem;

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
    gap: 2rem;
  }
`;

const Logo = styled.img`
  width: 650px;
  height: auto;
  
  @media (max-width: 1024px) {
    width: 350px;
  }

  @media (max-width: 768px) {
    width: 250px;
  }
`;

const TextBlock = styled.div`
  text-align: left;
  max-width: 600px;

  @media (max-width: 768px) {
    text-align: center;
    max-width: 100%;
  }
`;

const Title = styled.h2`
  font-size: 2rem;
  font-weight: bold;
  text-transform: uppercase;
  color: #f5b700;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const Subtitle = styled.p`
  font-size: 1.1rem;
  color: #ddd;
  margin-bottom: 1.5rem;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const List = styled.ul`
  list-style: none;
  padding: 0;
`;

const ListItem = styled.li`
  font-size: 1.2rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;

  &::before {
    content: "⭐";
    font-size: 1.5rem;
    margin-right: 10px;
    color: #f5b700;
  }

  a {
    text-decoration: none;
    color: white;
    font-weight: bold;
    transition: color 0.3s;

    &:hover {
      color: #f5b700;
    }
  }

  @media (max-width: 768px) {
    font-size: 1rem;

    &::before {
      font-size: 1.2rem;
    }
  }
`;

const UltraRaceSeries = () => {
  return (
    <Section>
      <Content>
        {/* Logo Ultra Race Series */}
        <Logo src={ultraRaceSeriesImage} alt="Ultra Race Series Logo" />

        {/* Tekstowa część */}
        <TextBlock>
          <Title>Odbierz książeczkę Gold Finishera</Title>
          <Subtitle>
            W pakiecie startowym <span style={{ color: "#f5b700" }}>Ultra Race Roztocze</span> otrzymasz specjalny dziennik, gdzie zbierasz pieczątki.
          </Subtitle>

          <Title>Zdobądź 4 pieczątki z każdego wyścigu:</Title>
          <List>
            <ListItem><a href="https://ultraraceroztocze.pl" target="_blank" rel="noopener noreferrer">Ultra Race Roztocze (9-11 maj 2025)</a></ListItem>
            <ListItem><a href="https://ultraracedolinabugu.pl" target="_blank" rel="noopener noreferrer">Ultra Race Dolina Bugu (5-7 wrzesień 2025)</a></ListItem>
            <ListItem><a href="https://ultraracesilesia.pl" target="_blank" rel="noopener noreferrer">Ultra Race Silesia (12-13 październik 2025)</a></ListItem>
            <ListItem><a href="https://ultraracepodlasie.pl" target="_blank" rel="noopener noreferrer">Ultra Race Podlasie (6 grudzień 2025 – Finał i Gala Finisherów!)</a></ListItem>
          </List>

          <Title>Dokończ wyzwanie i zdobądź tytuł!</Title>
          <Subtitle>
            Po ukończeniu <span style={{ color: "#f5b700" }}>Ultra Race Podlasie</span>, zostaniesz uhonorowany podczas oficjalnej gali finisherów.
          </Subtitle>
        </TextBlock>
      </Content>
    </Section>
  );
};

export default UltraRaceSeries;
