import * as React from "react"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import { Helmet } from "react-helmet"

import { HeroSection, HeroContent, Logo, Title, Subtitle, Button } from "../styles/HeroSectionStyle";
import UltraRaceSeries from "../components/UltraRaceSeries";
import GoldFinisherRewards from "../components/GoldFinisherRewards";

import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby";

import MotivationSection from "../components/MotivationSection";
import HallOfFame from "../components/HallOfFame";
import GallerySection from "../components/GallerySectionGF";

import styled from "styled-components";

const StyledGatsbyImage = styled(GatsbyImage)`
  width: 100%;
  max-width: 250px; /* Zwiększona szerokość */
  height: auto;
  margin-bottom: 1rem;

  @media (min-width: 768px) {
    max-width: 500px; /* Jeszcze większe logo na większych ekranach */
  }
`;
const Highlight = styled.span`
  color: #f5b700;
  font-weight: bold;
  text-transform: uppercase;
`;

const GoldFinisher = () => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "Logo-GF.png" }) {
        childImageSharp {
          gatsbyImageData(width: 300, layout: CONSTRAINED)
        }
      }
    }
  `);

  const logoImage = getImage(data.logo);

  return (
    <Layout>
      <Seo title="Gold Finisher Ultra Race Series 2025"/>
      <Helmet>
          <meta name="facebook-domain-verification" content="6w6wdb73vshsrnpixxhwgdkbryi1cg" />
          <meta name="description" content="Gold Finisher - zdobywaj tytuł w Ultra Race Series!" />
          <meta property="og:title" content="Gold Finisher Ultra Race Series 2025" />
          <meta property="og:image" content="/assets/images/Logo-GF.jpg" />
      </Helmet>

      {/* HERO */}
      <HeroSection>
          <HeroContent>
              <StyledGatsbyImage image={logoImage} alt="Gold Finisher Logo" /> {/* Poprawione! */}
              <Title>JEDEN CEL | CZTERY WYŚCIGI</Title>
              <Subtitle>
                  Zdobądź <Highlight>tytuł GOLD FINISHERA</Highlight>, pokonując 4 epickie wyścigi.
              </Subtitle>
              <Button href="https://b4sportonline.pl/ultra_race/" target="_blank">
                  Podejmuję wyzwanie!
              </Button>
          </HeroContent>
      </HeroSection>

      <UltraRaceSeries />
      <GoldFinisherRewards />
      <MotivationSection/>
      <HallOfFame />
      <GallerySection/>
    </Layout>
  );
}

export default GoldFinisher;
