import React from "react";
import styled from "styled-components";
import challengeIcon from "../assets/images/icon-challenge.png"; // Ikona wyzwania
import adventureIcon from "../assets/images/icon-adventure.png"; // Ikona przygody
import eliteIcon from "../assets/images/icon-elite.png"; // Ikona elity
import finishLineIcon from "../assets/images/icon-finish-line.png"; // Ikona mety

const Section = styled.section`
  background: linear-gradient(135deg, #1a1a1a, #2b2b2b);
  color: white;
  text-align: center;
  padding: 5rem 2rem;

  @media (max-width: 768px) {
    padding: 3rem 1rem;
  }
`;

const Title = styled.h2`
  font-size: 2.8rem;
  text-shadow: 2px 2px 10px rgba(245, 183, 0, 0.4);
  font-weight: bold;
  color: #f5b700;
  text-transform: uppercase;
  margin-bottom: 2rem;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const Subtitle = styled.p`
  font-size: 1.5rem;
  color: #ddd;
  margin-bottom: 2.5rem;

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

const IconsWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 3rem;
  margin-bottom: 3rem;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    gap: 2rem;
  }
`;

const IconBox = styled.div`
  text-align: center;
  max-width: 250px;

  img {
    width: 80px;
    height: auto;
    margin-bottom: 1rem;
  }

  h3 {
    font-size: 1.4rem;
    font-weight: bold;
    color: #f5b700;
  }

  p {
    font-size: 1.1rem;
    color: #ddd;
  }

  @media (max-width: 768px) {
    img {
      width: 60px;
    }

    h3 {
      font-size: 1.2rem;
    }

    p {
      font-size: 1rem;
    }
  }
`;

const CTAButton = styled.a`
   margin: 20px 10px;
    color: white;
    font-size: 1rem;
    border: 2px solid gold;
    font-weight: bold;
    padding: 12px 20px;
    text-transform: uppercase;
    cursor: pointer;
    transition: 0.4s ease-in-out;
    width: 280px;
    text-align: center;
    display: inline-block;
    background-color: transparent;
    color: gold;
    // border-radius: 30px;
    box-shadow: 5px 5px 10px rgba(66, 68, 90, 0.6);
    text-decoration: none;

    &:hover {
        background-color: gold;
        color: black;
    }

    @media (max-width: 768px) {
        width: 200px;
        font-size: 0.9rem;
        padding: 10px 15px;
    }
`;

const MotivationSection = () => {
  return (
    <Section>
      <Title>CZY MASZ W SOBIE DUCHA ULTRA?</Title>
      <Subtitle>Gold Finisher to więcej niż tylko wyścigi. To wyzwanie dla tych, którzy nie boją się przekraczać własnych granic.</Subtitle>

      <IconsWrapper>
        <IconBox>
          <img src={challengeIcon} alt="Wyzwanie" />
          <h3>Pokonaj swoje granice</h3>
          <p>Każdy start to test Twojej wytrzymałości i determinacji.</p>
        </IconBox>
        
        <IconBox>
          <img src={adventureIcon} alt="Przygoda" />
          <h3>Przeżyj przygodę życia</h3>
          <p>Odkryj najpiękniejsze trasy i doświadcz niezapomnianych chwil.</p>
        </IconBox>

        <IconBox>
          <img src={eliteIcon} alt="Elita" />
          <h3>Dołącz do elity</h3>
          <p>Gold Finisher to wyścigi dla prawdziwych pasjonatów ultra.</p>
        </IconBox>

        <IconBox>
          <img src={finishLineIcon} alt="Meta" />
          <h3>Udowodnij, że możesz</h3>
          <p>Finisz to dopiero początek Twojej legendy.</p>
        </IconBox>
      </IconsWrapper>

      <CTAButton href="https://b4sportonline.pl/ultra_race/" target="_blank">
        Podejmuję wyzwanie!
      </CTAButton>
    </Section>
  );
};

export default MotivationSection;
