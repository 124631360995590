import React from "react";
import styled from "styled-components";

const Section = styled.section`
  background: linear-gradient(135deg, #1a1a1a, #2b2b2b);
  color: white;
  text-align: center;
  padding: 4rem 2rem;

  @media (max-width: 768px) {
    padding: 3rem 1rem;
  }
`;

const Title = styled.h2`
  font-size: 2.5rem;
  text-shadow: 2px 2px 10px rgba(245, 183, 0, 0.5);
  font-weight: bold;
  color: #f5b700;
  text-transform: uppercase;
  margin-bottom: 1.5rem;

  @media (max-width: 768px) {
    font-size: 1.8rem;
  }
`;

const Subtitle = styled.p`
  font-size: 1.2rem;
  font-style: italic;
  color: #ddd;
  margin-bottom: 2rem;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

/* Układ list obok siebie na desktopie, jedna pod drugą na mobile */
const ListWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 2rem;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;

  @media (max-width: 768px) {
    gap: 1rem;
  }
`;

const List = styled.ul`
  list-style: none;
  padding: 1.5rem;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  width: 100%;
  max-width: 500px;
  text-align: left;
  box-shadow: 0 0 15px rgba(255, 215, 0, 0.3);

  @media (max-width: 768px) {
    max-width: 100%;
    padding: 1rem;
  }
`;

const ListTitle = styled.h3`
  font-size: 1.5rem;
  font-weight: bold;
  color: #f5b700;
  text-align: center;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

const ListItem = styled.li`
  font-size: 1.2rem;
  padding: 0.5rem 0;
  font-weight: light;
  display: flex;
  align-items: center;

  &::before {
    content: "🏆";
    font-size: 1.5rem;
    margin-right: 10px;
  }

  @media (max-width: 768px) {
    font-size: 1rem;
    &::before {
      font-size: 1.2rem;
    }
  }
`;

const HallOfFame = () => {
  return (
    <Section>
      <Title>Złota Lista 2024</Title>
      <Subtitle>Najwytrwalsi zawodnicy sezonu!</Subtitle>

      <ListWrapper>
        {/* Lista za 4 starty w 2024 */}
        <List>
          <ListTitle>🏅 Nagroda za 4 starty w sezonie 2024</ListTitle>
          <ListItem>Arkadiusz Dąbrowski</ListItem>
          <ListItem>Juliusz Kowalczyk</ListItem>
          <ListItem>Rafał Adamczuk</ListItem>
          <ListItem>Tomek Lubowiecki</ListItem>
          <ListItem>Mirosław Kecik</ListItem>
        </List>

        {/* Lista za 5/6 startów w 2023 + 2024 */}
        <List>
          <ListTitle>🏅 Nagroda za 5/6 startów od 2023</ListTitle>
          <ListItem>Katarzyna Partyka</ListItem>
          <ListItem>Katarzyna Myśliwiec-Prawda</ListItem>
          <ListItem>Juliusz Kowalczyk</ListItem>
          <ListItem>Tomasz Kamiński</ListItem>
          <ListItem>Grzegorz Musiejuk</ListItem>
          <ListItem>Robert Rybak</ListItem>
          <ListItem>Rafał Adamczuk</ListItem>
        </List>
      </ListWrapper>
    </Section>
  );
};

export default HallOfFame;
